import { Box, Flex, Paragraph } from "theme-ui";
import Section from "./components/Section";
import { useTranslations } from "./contexts/Translation";
import { useOrganizations } from "./contexts/UserProvider";
import { Info } from "emotion-icons/material";
import Button from "./components/Button";
import { useNavigate } from "react-router-dom";
import { H3 } from "./components/Headers";


const CheckOrganization = () => {
  const { currentOrganization } = useOrganizations();
  const t = useTranslations('dashboard');
  const navigate = useNavigate();
  if (currentOrganization) {
    return null;
  }

  return (
    <Section>
      <Flex sx={{
        alignItems: 'flex-start',
        border: '2px',
        borderColor: 'borderColor',
        background: 'tertiaryBackground',
        borderRadius: 6,
        py: 12,
        px: 20,
        'svg': { color: 'borderColor', flexShrink: 0 },
      }}>
        <Info size="1.5rem" />
        <Box sx={{ ml: 10 }}>
          <H3 sx={{ mt: 0 }}>{t('noOrgTitle')}</H3>
          <Paragraph>{t('noOrgDescription')}</Paragraph>
          <Button sx={{ my: 12, border: '1px solid #BF98BE', backgroundColor: 'secondaryCta' }} onClick={() => navigate(`/register-organization`)}>{t('noOrgButton')}</Button>
          <Button sx={{ my: 12, border: '1px solid #BF98BE', backgroundColor: 'secondaryCta' }} onClick={() => navigate(`/join-organization`)}>{t('joinOrganization')}</Button>
        </Box>
      </Flex>
    </Section>
  );
};

export default CheckOrganization;
