import { useTranslations } from "../../contexts/Translation";
import { Box, Button, Grid } from "theme-ui";
import { H3 } from "../Headers";
import { ValidationError } from "../../types";
import ErrorText from "../Forms/ErrorText";
import { useState } from "react";
import { getScoreDescription } from "../../lib/getScoreDescription";

type AnswerProps = {
  onChange: Function;
  question: string;
  value?: number | undefined | null;
  candidate: string;
  error?: ValidationError | null,
}

const Answer = ({ onChange, question, value, candidate, error }: AnswerProps) => {
  const t = useTranslations('questions');
  const btnSx = {
    m: 0, mr: 4, py: 8, px: 16, backgroundColor: 'muted', color: 'mainText', borderRadius: 0,
    cursor: 'pointer', ':hover': { backgroundColor: 'secondaryBackground' }
  };
  const [currentScore, setCurrentScore] = useState<number | undefined | null>(value);

  return (
    <Box>
      <H3>{t(question, candidate)}</H3>
      <Box>{t(`${question}Info`)}</Box>
      <Grid sx={{ my: 16, p: 8, gridTemplateColumns: ['repeat(3, 1fr)', 'repeat(12, 1fr)'], justifyContent: 'space-evenly', border: error ? '3px solid #b20303' : 'none' }}>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(score => {
          const scoreSx = value === score ? { backgroundColor: 'responderPrimary', color: 'white' }: {};
          return (
            <Button key={score} onClick={e => {
              e.preventDefault();
              setCurrentScore(score);
              onChange(question, score);
            }
          }
          sx={{ ...btnSx, ...scoreSx, gridColumn: score === 0 ?'1 / span 2;':0 }}>{score === 0 ? t('dontKnow') : score}</Button>
          );
        })}
      </Grid>
      { currentScore && currentScore > 0 ? <Box sx={{ borderRadius: '5px', backgroundColor: 'primary', p: 16  }}>{ getScoreDescription(t, currentScore) }</Box>  : null}
      <ErrorText error={error} />
    </Box>
  );
}
  

export default Answer;
