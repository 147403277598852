import { Box, Spinner } from "theme-ui";
import { useTranslations } from "../../contexts/Translation";
import Section from "../Section";
import { CalendarError, ErrorIcon } from "../Icons";

type SystemMessageProps = {
  message: string;
  isError?: boolean;
}

const Main = ({ message, isError = false }: SystemMessageProps) => {
  const t = useTranslations('systemMessages');

  return (
    <Box as="main">
      <Section sx={{ width: 300, textAlign: 'center', mt: '10%', color: isError ? 'error': 'mainText' }}>
        { message === 'loading' && <Spinner sx={{ size: 200 }} duration={2000} strokeWidth={4} /> }
        { (message === 'notFound' || message === 'errorLogin') && <ErrorIcon width="200px" height="200px" />}
        { message === 'surveyEnded' && <CalendarError width="200px" height="200px" />}
        { message === 'alreadyResponded' && <CalendarError width="200px" height="200px" />}
        { ['contactFormError', 'invalidDomain'].includes(message) && <ErrorIcon width="200px" height="200px" /> }
        <Box sx={{ fontSize: 24 }}>{t(message)}</Box>
      </Section>
    </Box>
  );
};

export default Main;
