import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Box, Input } from "theme-ui";
import Section from "../Section";
import { useTranslations } from "../../contexts/Translation";
import { useApiData } from "../../contexts/ApiData";
import SystemMessage from '../SystemMessage/Index';
import Button from "../Button";
import { H2 } from "../Headers";

const getOrgIdFromUrl = (path: string) => {
  const parts = path.split('/');
  return parts[parts.length -1];
};

export const JoinOrganization = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { get, post } = useApiData();
  const t = useTranslations('join');
  const [org, setOrg] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [urlError, setUrlError] = useState<string | null>(null);

  const [orgId, setOrgId] = useState<string | null>(null);

  const lookupOrganization = (e: any) => {
    setUrlError(null);
    setOrgId(getOrgIdFromUrl(e.currentTarget.value));
  };

  useEffect(() => {
    const fetchOrg = async () => {
      const response = await get(`/org/${orgId}`);
      if (!response) {
        setUrlError(t('orgFetchError'));
        return;
      }
      setOrg(response);
    };

    if (orgId && !org && user) {
      fetchOrg();
    }
  }, [get, orgId, user, t, org]);

  if (error) {
    return <SystemMessage message={error} />;
  }

  if (!org) {
    return (
      <Section>
        <H2>{t('joinByLinkHeading')}</H2>
        <label>{t('pasteLink')}
          <Input sx={{ p: 8, width: '85%', display: 'inline' }} type="text" onBlur={lookupOrganization} />
          { urlError && <Box sx={{ color: 'error' }}>{urlError}</Box> }
          <Button sx={{ mt: 16 }} onClick={() => lookupOrganization}>{t('okButton')}</Button>
        </label>
      </Section>
    );
  }

  const joinFn = async () => {
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await post(`/organization/${org.id}/users`);
      if (response) {
        await getAccessTokenSilently({ cacheMode: "off" });
        window.location.href = '/';
      } else {
        setError('joinFailed');
      }
    } catch (err) {
      setError(t('joinFailed'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
      <Section sx={{ mt: 96, textAlign: 'center' }}>
        {t('aboutTo', org.name)}
        {org.logo && (
          <Box sx={{ mt: 32 }}>
            <img 
              style={{ maxHeight: '100px', maxWidth: '150px' }} 
              src={org.logo} 
              alt={org.name} 
            /> 
          </Box>
        )}
        <Box>
          <Button 
            sx={{ mt: 96, py: 28, px: 48 }} 
            onClick={joinFn} 
            disabled={isSubmitting}
          >
            {isSubmitting ? t('joining') : t('join', org.name)}
          </Button>
        </Box>
        {error && (
          <Box sx={{ mt: 16, color: 'error' }}>
            {error}
          </Box>
        )}
      </Section>
  );
};
