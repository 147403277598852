import { Box, Flex, Paragraph } from "theme-ui";
import { Info } from "emotion-icons/material";
import Section from "../Section";
import { H3 } from "../Headers";
import Button from "../Button";
import { useLocation, useNavigate } from "react-router-dom";

export const ActiveCoupon = ({ title, body, button, coupon }: { title?: string; body: string; button?: string; coupon: string; }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;
  if (!['/', ''].includes(pathname)) {
    return null;
  }

  return (
    <Section>
      <Flex sx={{
        alignItems: 'flex-start',
        border: '2px',
        borderColor: 'borderColor',
        background: 'tertiaryBackground',
        borderRadius: 6,
        py: 12,
        px: 20,
        'svg': { color: 'borderColor', flexShrink: 0 },
      }}>
        <Info size="1.5rem" />
        <Box sx={{ ml: 10 }}>
          { title && <H3 sx={{ mt: 0 }}>{title}</H3> }
          <Paragraph>{body}</Paragraph>
          <Paragraph sx={{ fontWeight: 'bold', fontSize: '1.4rem'}}>{coupon}</Paragraph>

          { button && <Button sx={{ my: 12, border: '1px solid #BF98BE', backgroundColor: 'secondaryCta' }} onClick={() => navigate(`/activate-subscription`)}>{button}</Button> }
        </Box>
      </Flex>
    </Section>
  );
}
