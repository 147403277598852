import { useTranslations } from "../../../contexts/Translation";
import { useState } from 'react';
import { Box } from "theme-ui";
import { useApiData } from "../../../contexts/ApiData";
import { ValidationError } from "../../../types";
import Input from "../Input";
import { H2 } from "../../Headers";
import Button from "../Button";
import Section from "../../Section";
import SystemMessage from '../../SystemMessage/Index';

type ContactFormData = {
  message: string;
}

const ContactForm = () => {
  const t = useTranslations('contactUsForm');

  const { post } = useApiData();
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const initialData: ContactFormData = {
    message: '',
  };
  const [formData, setFormData] = useState<ContactFormData>(initialData);
  const [showReceipt, setShowReceipt] = useState(false);
  const [serverError, setServerError] = useState(false);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setErrors(errors.filter(err => err.name !== e.target.name));
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.message.length === 0) {
      setErrors([{ name: 'message', message: t('messageError') }]);
    }
    
    const response = await post('/contact-us', formData);
    if (!response) {
      setServerError(true);
    } else {
      setFormData(initialData);
      setShowReceipt(true);
    }
  };

  if (serverError) {
    return <SystemMessage message={'contactFormError'} />;
  }

  return (
    <Section>
    <Box as="form" onSubmit={handleSubmit}>
      <H2>{t('heading')}</H2>
      <Box sx={{
        '> div': { mb: 0 },
        'input': { mb: 0 },
      }}>
        <Input
          type="textarea"
          name="message"
          handleChange={handleChange}
          label={t('messageLabel')}
          value={formData.message}
          error={errors.find(e => e.name === 'message')}
        />
      </Box>
      <Button>{t('submit')}</Button>
      { showReceipt && <Box sx={{ mt: 16 }}>{t('receipt')}</Box> }
    </Box>
    </Section>
  );
};

export default ContactForm;