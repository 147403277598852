
export const getScoreDescription = (t: any, score: number | undefined | null) => {
  switch (score) {
    case 10:
      return t('questions.score10', score);
    case 9:
      return t('questions.score9', score);
    case 8:
    case 7:
      return t('questions.score7to8', score);
    case 6:
    case 5:
      return t('questions.score5to6', score);
    case 4:
    case 3:
    case 2:
    case 1:
      return t('questions.score1to4', score);
    default:
      return null;
  }
};